import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Beautiful New Tab",
  "time": "stuff",
  "index": 2,
  "type": "project",
  "hidden": false,
  "splash": "Stylish New Tab page putting you one keystroke away from your favourite sites"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const GitLink = makeShortcode("GitLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1><a parentName="h1" {...{
        "href": "https://github.com/DavidPeet8/Beautiful-NewTab"
      }}>{`New Tab`}</a></h1>
    <p><strong parentName="p">{`Date Range:`}</strong>{` 2018`}</p>
    <p><strong parentName="p">{`Key Technologies:`}</strong>{` `}<inlineCode parentName="p">{`JavaScript`}</inlineCode>{` `}<inlineCode parentName="p">{`FireBase`}</inlineCode></p>
    <p><strong parentName="p">{`Github Source:`}</strong>{` `}<GitLink url="https://github.com/DavidPeet8/Beautiful-NewTab" mdxType="GitLink" /></p>
    <hr></hr>
    <p>{`A simple chrome new tab page customization with a few added features:`}</p>
    <ol>
      <li parentName="ol">{`Hotkeys to launch favorite websites`}</li>
      <li parentName="ol">{`A space to record ideas throughout the day`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      